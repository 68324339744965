import React from 'react';
import HeadingSection from '../HeadingSection/HeadingSection';
import { Container, Row, Col } from 'emotion-flex';
import { ContentfulContentBlock } from '../../graphql-types';
import hexToRgba from 'hex-to-rgba';
import { SYSTEM_FONTS } from '../../styles/typography';
import presets from '../../styles/presets';

const Perks: React.FC<ContentfulContentBlock> = ({ items, ...rest }) => {
  return (
    <section
      css={{
        marginTop: 129,
        [presets.xl]: {
          marginTop: 218,
        },
      }}
    >
      <HeadingSection {...rest} />
      <Container css={{ margin: '114px auto 0' }}>
        <div
          css={{
            [presets.lg]: {
              marginLeft: 80,
            },
          }}
        >
          <Row>
            {items.map((item) => {
              return (
                <Col
                  key={item.heading}
                  xs={12}
                  lg={4}
                  css={{
                    textAlign: 'center',
                    [presets.lg]: {
                      textAlign: 'left',
                    },
                  }}
                >
                  <div
                    css={{
                      minHeight: 300,
                      [presets.xl]: {
                        minHeight: 360,
                      },
                    }}
                  >
                    <img
                      src={item.image.fluid.src}
                      alt={item.heading}
                      css={{
                        minWidth: 200,
                        height: 'auto',
                        margin: '0 auto',

                        [presets.lg]: {
                          maxWidth: 320,
                        },
                        [presets.lg]: {
                          position: 'absolute',
                          maxWidth: '90%',
                        },
                      }}
                    />
                  </div>
                  <h5
                    css={{
                      fontFamily: ['Blacker Pro Display']
                        .concat(SYSTEM_FONTS)
                        .join(', '),
                      fontSize: 20,
                      fontWeight: 600,
                      color: '#160B2C',
                      marginTop: 10,
                      marginBottom: 18,
                    }}
                  >
                    {item.heading}
                  </h5>
                  <p
                    css={{
                      fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
                      fontSize: 16,
                      color: hexToRgba('#160B2C', 0.8),
                      marginBottom: 80,
                      [presets.xl]: {
                        marginBottom: 216,
                      },
                    }}
                  >
                    {item.shortText}
                  </p>
                </Col>
              );
            })}
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default Perks;
