import React, { useState } from 'react';
import { css } from '@emotion/core';
import HeadingSection from '../HeadingSection/HeadingSection';
import background from '../../assets/images/careers-background1.svg';
import ArrowIcon from '../../assets/images/arrow-icon.svg';
import Container from '../Homepage/Container';
import presets from '../../styles/presets';
import {
  ContentfulContentBlock,
  ContentfulHeadingTextImage,
} from '../../graphql-types';
import { Col, Row } from 'emotion-flex';
import hexToRgba from 'hex-to-rgba';
import { SYSTEM_FONTS } from '../../styles/typography';
import GatsbyImage from 'gatsby-image';
import styled from '@emotion/styled';

interface ArrowImageProps {
  isActive: boolean;
}
interface ItemBoxProps {
  isActive: boolean;
  index: number;
  currentIndex: number;
}

const ItemBox = styled.div<ItemBoxProps>`
  transition: 0.3s ease-in-out;
  padding: ${({ isActive }) =>
    isActive ? `24px 19px 24px 19px` : `0 19px 0 19px`};
  ${presets.md} {
    padding: ${({ isActive }) =>
      isActive ? `50px 25px 45px 32px` : `0 25px 0 32px`};
  }
  min-height: ${({ isActive }) => (isActive ? 180 : 80)}px;
  background-color: ${({ isActive }) =>
    isActive ? hexToRgba('#ffdad5', 0.25) : 'none'};
  border-radius: ${({ isActive }) => (isActive ? 10 : 0)}px;
  ${({ isActive, index, currentIndex }) =>
    !isActive &&
    `
    border-bottom: ${
      currentIndex - index !== 1 && `0.2px solid ${hexToRgba('#160B2C', 0.2)}`
    } ;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

const StyledHeading = styled.p`
  font-family: ${['Blacker Pro Display'].concat(SYSTEM_FONTS).join(', ')};
  font-size: 20px;
  font-weight: bold;
  color: ${hexToRgba('#160B2C', 0.8)};
  margin-bottom: 0;
`;

const ArrowImage = styled.img<ArrowImageProps>`
  margin-bottom: 0;
  margin-left: 7px;
  transition: 0.25s ease-in-out;
  max-width: 16px;
  transform: ${({ isActive }) => (isActive ? 'rotate(0)' : 'rotate(180deg)')};
  &:hover {
    cursor: pointer;
  }
`;

const About = ({
  heading,
  boxes,
}: {
  heading: ContentfulContentBlock;
  boxes: ContentfulHeadingTextImage[];
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <section css={{ marginTop: 17.5 }}>
      <div
        css={css`
          background-image: url(${background});
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: cover;
          min-height: 920px;
          padding-top: 128px;

          ${presets.md} {
            min-height: 1000px;
            padding-top: 171.5px;
          }
          ${presets.lg} {
            min-height: 1100px;
          }

          @media (min-width: 1930px) {
            padding-top: 12vw;
            min-height: 60vw;
          }
        `}
      >
        <Container>
          <HeadingSection {...heading} />
          <Row css={{ maxWidth: 1050 + 44, margin: '69px auto 0' }}>
            <Col
              xs={12}
              md={6}
              css={{
                display: 'none',
                height: 600,
                padding: '0 11px',
                [presets.md]: {
                  display: 'block',
                },
              }}
            >
              <GatsbyImage
                fluid={boxes[activeIndex].image.fluid}
                css={{
                  width: '100%',
                  height: 450,
                  borderRadius: 10,
                  [presets.lg]: { height: 600 },
                }}
              />
            </Col>
            <Col
              xs={12}
              md={6}
              css={{
                padding: '14px 11px 0',
                [presets.lg]: { padding: '53px 11px 0' },
              }}
            >
              {boxes.map((el, index) => {
                return (
                  <ItemBox
                    key={el.heading}
                    isActive={activeIndex === index}
                    index={index}
                    currentIndex={activeIndex}
                    onClick={() => {
                      setActiveIndex(index);
                    }}
                  >
                    {activeIndex === index ? (
                      <>
                        <div
                          css={{
                            display: 'flex',
                            alignItems: 'baseline',
                            justifyContent: 'space-between',
                            minHeight: 40,
                          }}
                        >
                          <StyledHeading>{el.heading}</StyledHeading>
                          <div css={{ display: 'flex', userSelect: 'none' }}>
                            <ArrowImage
                              src={ArrowIcon}
                              alt="Arrow"
                              isActive={activeIndex === index}
                            />
                          </div>
                        </div>
                        <div css={{ maxWidth: 400 }}>
                          <p
                            css={{
                              fontFamily: ['Open Sans']
                                .concat(SYSTEM_FONTS)
                                .join(', '),
                              fontSize: 14,
                              color: hexToRgba('#160B2C', 0.8),
                              marginTop: 8,
                              marginBottom: 0,

                              [presets.md]: { marginTop: 0 },
                            }}
                          >
                            {el.text.childMarkdownRemark.rawMarkdownBody}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <StyledHeading>{el.heading}</StyledHeading>
                        <div css={{ display: 'flex', userSelect: 'none' }}>
                          <ArrowImage
                            src={ArrowIcon}
                            alt="Arrow"
                            isActive={activeIndex === index}
                          />
                        </div>
                      </>
                    )}
                  </ItemBox>
                );
              })}
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default About;
