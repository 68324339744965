import React from 'react';
import presets from '../../styles/presets';
import { ContentfulContentBlock } from '../../graphql-types';
import HeadingSection from '../HeadingSection/HeadingSection';
import Container from '../Homepage/Container';
import { Col } from 'emotion-flex';
import hexToRgba from 'hex-to-rgba';
import { SYSTEM_FONTS } from '../../styles/typography';
import { Slider } from '..';
import { css } from '@emotion/core';

const Introduce = (props: ContentfulContentBlock) => {
  const teamSlidersettings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true,
    centerPadding: '120px',
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          centerPadding: '40px',
          slidesToShow: 2.86,
        },
      },
      {
        breakpoint: 1760,
        settings: {
          // centerPadding: '120px',
          slidesToShow: 2.6,
        },
      },
      {
        breakpoint: 1176,
        settings: {
          centerPadding: '33px',
          slidesToShow: 1.92,
        },
      },
      {
        breakpoint: 940,
        settings: {
          centerPadding: '20px',
          slidesToShow: 1.99,
        },
      },
      {
        breakpoint: 780,
        settings: {
          centerPadding: '15px',
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          centerPadding: '15px',
          slidesToShow: 1,
        },
      },
    ],
  };

  //   :focus {
  //     outline: -webkit-focus-ring-color auto 1px;
  // }

  return (
    <section
      css={{
        maxWidth: 2000,
        margin: '178px auto 250px',
      }}
    >
      <Container>
        <HeadingSection {...props} />
      </Container>
      <Slider {...teamSlidersettings}>
        {props.itemsLongText.map((item) => {
          return (
            <Col
              key={item.heading}
              css={css`
                text-align: center;
                padding-top: 0;

                @media (min-width: 325px) {
                  padding-top: 17vw;
                }

                @media (min-width: 450px) {
                  padding-top: 23vw;
                }

                @media (min-width: 576px) {
                  padding-top: 0px;
                  max-width: 400px;
                }

                @media (min-width: 640px) {
                  padding-top: 60px;
                }

                @media (min-width: 768) {
                  padding-top: 100px;
                }
              `}
            >
              <div
                css={{
                  minHeight: 240,
                  margin: '0 auto',
                  position: 'relative',
                  [presets.xl]: {
                    minHeight: 295,
                  },
                }}
              >
                <img
                  src={item.image?.fluid.src}
                  alt={item.heading}
                  css={{
                    maxWidth: '80%',
                    // maxWidth:
                    //   (index === 0 && '60%') || (index === 2 && '85%') || '80%',
                    height: 'auto',
                    position: 'absolute',
                    left: '50%',
                    transform: 'translate(-50%)',
                    bottom: 5,
                    // bottom: (index === 2 && -16) || (index === 4 && -37) || 5,
                  }}
                />
              </div>
              <div>
                <p
                  css={{
                    fontFamily: ['Blacker Pro Display']
                      .concat(SYSTEM_FONTS)
                      .join(', '),
                    fontSize: 24,
                    fontWeight: 600,
                    color: hexToRgba('#160B2C', 0.8),
                    marginBottom: 4,
                  }}
                >
                  {item.prefix}
                </p>
                <h5
                  css={{
                    fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
                    fontSize: 18,
                    fontWeight: 400,
                    color: hexToRgba('#160B2C', 0.8),
                    marginBottom: 22,
                  }}
                >
                  {item.heading}
                </h5>
                <p
                  css={{
                    fontFamily: ['Open Sans'].concat(SYSTEM_FONTS).join(', '),
                    fontSize: 14,
                    color: hexToRgba('#160B2C', 0.8),
                  }}
                >
                  {item.text.childMarkdownRemark.rawMarkdownBody}
                </p>
              </div>
            </Col>
          );
        })}
      </Slider>
    </section>
  );
};

export default Introduce;
